/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Dropdown } from '@autone/ui';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Mixpanel } from '../utils/mixpanel';
import { CHANGE_CALC_TYPE } from '../utils/mixpanel/eventTypes';

function CalcType({ handleChangeCallback, calcType, isFullScreen }) {
  const CALC_OPTIONS = [
    { value: 'actual', label: 'Actual' },
    { value: 'mix', label: 'Mix' },
    { value: 'tickets', label: 'Tickets' },
    { value: 'upt', label: 'UPT' },
    { value: 'avt', label: 'AVT' },
  ];

  function renderLabel(label) {
    if (label === 'actual') {
      return 'Actual';
    }
    if (label === 'mix') {
      return 'Mix';
    }
    if (label === 'tickets') {
      return 'Tickets';
    }
    if (label === 'upt') {
      return 'UPT';
    }
    if (label === 'avt') {
      return 'AVT';
    }
    return null;
  }

  const handleChange = (value) => {
    handleChangeCallback(value);
    Mixpanel.track(CHANGE_CALC_TYPE, { sort_by: value });
  };

  return (
    <Dropdown
      value={calcType}
      currentLabel={renderLabel(calcType)}
      title="view"
      isFullScreen={isFullScreen}
    >
      {CALC_OPTIONS.map((option) => (
        <MenuItem
          key={option.value}
          selected={option.value === calcType}
          onClick={() => handleChange(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Dropdown>
  );
}

CalcType.propTypes = {
  calcType: PropTypes.string,
  handleChangeCallback: PropTypes.func,
  isFullScreen: PropTypes.bool,
};

export default CalcType;
