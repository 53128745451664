import { fNumberWithCommas, fReadableCurrency, Label } from '@autone/ui';
import { Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useBestsellersMetrics from '../../hooks/useBestsellersMetrics';
import { useAppSelector as useSelector } from '../../redux/store';

const BestsellerMetrics = () => {
  const { totalSalesQuantity, totalSalesValue, areMetricsLoading } =
    useBestsellersMetrics();

  //@ts-ignore not yet typed
  const { selectedCurrency } = useSelector((state) => state.priceOptions);

  const skeletonProps = {
    height: '22px',
    width: '170px',
  };

  return (
    <Stack
      gap={2}
      direction="row"
      sx={{
        mb: 1,
        px: 0.5,
        alignItems: 'center',
      }}
    >
      {!areMetricsLoading && totalSalesQuantity && totalSalesValue ? (
        <>
          <Stack direction="row" gap={1}>
            <Typography>Total sales:</Typography>
            <Label color="primary">
              {fReadableCurrency(totalSalesValue, selectedCurrency)}
            </Label>
            <Label color="primary">
              {fNumberWithCommas(totalSalesQuantity)} units
            </Label>
          </Stack>
        </>
      ) : (
        <>
          <Skeleton {...skeletonProps} />
        </>
      )}
    </Stack>
  );
};

export default BestsellerMetrics;

BestsellerMetrics.propTypes = {
  bestsellerMetrics: PropTypes.object,
  isLoading: PropTypes.bool,
};
