import { ADD_DATE_SELECTIONS } from '../../redux/filters';

export const handleDateSelections = ({
  dispatch,
  dimension,
  title,
  operator,
  options,
  renderInSidebar,
  activePage,
}) =>
  dispatch(
    ADD_DATE_SELECTIONS({
      dimension,
      title,
      operator,
      options,
      renderInSidebar,
      activePage,
    }),
  );
