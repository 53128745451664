export const filters = {
  dimensions: [
    {
      id: 'Location.regionDescription',
      dimension: 'Location.regionDescription',
      title: 'Region',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Ticket.shippingCountry',
      dimension: 'Ticket.shippingCountry',
      title: 'Customer Country',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Location.countryDescription',
      dimension: 'Location.countryDescription',
      title: 'Location Country',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Location.description',
      dimension: 'Location.description',
      title: 'Location',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Location.typeDescription',
      dimension: 'Location.typeDescription',
      title: 'Location Type',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Sales.transactionType',
      dimension: 'Sales.transactionType',
      title: 'Transaction Type',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Sales.markdown',
      dimension: 'Sales.markdown',
      title: 'Sales Type',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.seasonDescription',
      dimension: 'Product.seasonDescription',
      title: 'Season',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.departmentDescription',
      dimension: 'Product.departmentDescription',
      title: 'Department',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.genderDescription',
      dimension: 'Product.genderDescription',
      title: 'Gender',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.subDepartmentDescription',
      dimension: 'Product.subDepartmentDescription',
      title: 'Sub Department',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.product_id',
      dimension: 'Product.product_id',
      title: 'Product Id',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Product.brandDescription',
      dimension: 'Product.brandDescription',
      title: 'Brand',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Product.colorDescription',
      dimension: 'Product.colorDescription',
      title: 'Color',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Sku.sizeId',
      dimension: 'Sku.sizeId',
      title: 'Size',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Product.colorGroup',
      dimension: 'Product.colorGroup',
      title: 'Color Group',
      renderInSidebar: true,
      operator: 'equals',
    },
    {
      id: 'Ticket.salesAssociateDescription',
      dimension: 'Ticket.salesAssociateDescription',
      title: 'Sales Associate',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Sales.ticketId',
      dimension: 'Sales.ticketId',
      title: 'Ticket Id',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Sales.retailYear',
      dimension: 'Sales.retailYear',
      title: 'Year',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Sales.retailMonth',
      dimension: 'Sales.retailMonth',
      title: 'Month',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Sales.retailWeek',
      dimension: 'Sales.retailWeek',
      title: 'Week',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Sales.date',
      dimension: 'Sales.date',
      title: 'Date',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Product.stylisticGroupDescription',
      dimension: 'Product.stylisticGroupDescription',
      title: 'Product family',
      renderInSidebar: false,
      operator: 'equals',
    },
    {
      id: 'Product.styleDescription',
      dimension: 'Product.styleDescription',
      title: 'Product Style',
      renderInSidebar: false,
      operator: 'equals',
    },
  ],
};
