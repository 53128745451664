/* eslint-disable no-return-await */
import {
  AppShell,
  ErrorFallback,
  INSIGHTS_READONLY,
  useAppSettings,
} from '@autone/ui';
import { authRedirectCheckRtkQuery } from '@autone/ui/src/utils/auth';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// cubejs

import { cubeAPIUrlDict } from './cube';
import store from './redux/store';
import AppRoutes from './routes/app.routes';

function App() {
  const routes = [AppRoutes];

  const cubejsApi = cubejs(
    async () => {
      const { idToken } = await authRedirectCheckRtkQuery();
      return idToken;
    },
    {
      apiUrl:
        process.env.REACT_APP_CUBE_API_URL ||
        cubeAPIUrlDict[process.env.REACT_APP_BUILD_STAGE],
    },
  );

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CubeProvider cubejsApi={cubejsApi}>
        <AppShell
          reduxStore={store}
          routes={routes}
          permissionGroups={[INSIGHTS_READONLY]}
        />
      </CubeProvider>
    </ErrorBoundary>
  );
}

export default App;
