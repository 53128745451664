import { RadioItems } from '@autone/ui';
import {
  Divider,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  Radio,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { ParameterOption } from '../../redux/filters';
import { Dimension } from '../../types';

const ParameterRadioOptions = ({
  parameterOptions,
  handleFilterClickCallback,
}: {
  parameterOptions: ParameterOption[];
  handleFilterClickCallback: (
    dimension: Dimension,
    id: string,
    active: boolean,
    type: string,
  ) => void;
}) => {
  const theme = useTheme();
  const handleRadioSelection = (
    _e: React.ChangeEvent,
    dimension: Dimension,
    id: string,
  ) => handleFilterClickCallback(dimension, id, false, 'parameters');

  return (
    <>
      {parameterOptions &&
        parameterOptions.map(({ title, dimension, options }) => {
          // options always exist in parameter options
          const activeSelection = options.find((item) => item.active);
          return (
            <RadioItems
              key={title}
              title={title}
              renderCustomComponent
              openByDefault={false}
            >
              <MUIRadioGroup
                value={activeSelection?.id}
                onChange={(e, id) => {
                  handleRadioSelection(e, dimension, id);
                }}
              >
                {options &&
                  options.map(({ id, title }) => (
                    <FormControlLabel
                      sx={{
                        borderRadius: `${theme.shape.borderRadius}px`,
                        textTransform: 'none',
                        width: 'auto',
                        marginRight: theme.spacing(2),
                        marginLeft: theme.spacing(2),
                        height: 40,
                        '&:hover': {
                          background: '#f0f5fd',
                        },
                      }}
                      key={id}
                      value={id}
                      control={
                        <Radio
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        />
                      }
                      label={title}
                    />
                  ))}
              </MUIRadioGroup>
            </RadioItems>
          );
        })}
      <Divider sx={{ mt: 1 }} />
    </>
  );
};

export default ParameterRadioOptions;

ParameterRadioOptions.propTypes = {
  parameterOptions: PropTypes.array,
  handleFilterClickCallback: PropTypes.func,
};
