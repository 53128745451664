import { useAutoneCube, useBuildCubeQuery } from '../cube';

import useGetDebouncedFilters from './useGetDebouncedFilters';

const useBestsellersMetrics = () => {
  const { filters, activePage } = useGetDebouncedFilters();

  const { cubeQuery } = useBuildCubeQuery({
    query: [
      {
        dataKey: 'metrics',
        query: {
          measure: ['Sales.totalSalesQuantity', 'Sales.totalSalesValue'],
        },
      },
    ],
    filters,
    activePage,
  });

  const { resultSet, isLoading: areMetricsLoading } = useAutoneCube({
    query: cubeQuery,
    type: 'table',
  });

  const totalSalesQuantity = resultSet
    ? Number(resultSet?.[0]?.results?.[0]?.['Sales.totalSalesQuantity'])
    : null;

  const totalSalesValue = resultSet
    ? Number(resultSet?.[0]?.results?.[0]?.['Sales.totalSalesValue'])
    : null;

  return {
    totalSalesQuantity,
    totalSalesValue,
    areMetricsLoading,
  };
};

export default useBestsellersMetrics;
