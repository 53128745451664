import { isKeyInType } from '@autone/ui';

import { ADD_SINGLE_SELECTIONS } from '../../redux/filters';
import store, { AppDispatch } from '../../redux/store';
import { Option } from '../../types/filters';
import { Mixpanel } from '../../utils/mixpanel';
import { FILTER } from '../../utils/mixpanel/eventTypes';

export const handleSingleSelections = ({
  dispatch,
  dimension,
  operator,
  option,
  renderInSidebar,
  activePage,
}: {
  dispatch: AppDispatch;
  dimension: string;
  operator: string;
  option: Option;
  renderInSidebar: boolean;
  activePage: string;
}) => {
  // get current filters
  const { filters } = store.getState().filters;

  // check if selections exist in rendered sidebar filters
  const filterGroupExists =
    filters &&
    activePage &&
    isKeyInType(filters, activePage) &&
    filters[activePage]?.some(
      (filterGroup) => filterGroup.dimension === dimension,
    );

  if (filterGroupExists) {
    dispatch(
      // note options is an object, not array for single selections
      ADD_SINGLE_SELECTIONS({
        dimension,
        operator,
        option,
        renderInSidebar,
        activePage,
      }),
    );

    Mixpanel.track(FILTER, {
      dimension,
      option,
    });
  }
};
