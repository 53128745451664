/* eslint-disable complexity */
import { Label } from '@autone/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { mixCompare } from '../../config/retail/calcs';

const MixCurentComponent = ({ dataRow, dataKey, currentKey, totals }) => {
  const mixCompareResult = mixCompare(dataRow, dataKey, currentKey, totals);

  return (
    <Label variant="filled" color={mixCompareResult > 0 ? 'success' : 'error'}>
      {`${mixCompareResult > 0 ? '+' : ''} ${mixCompareResult} pp`}
    </Label>
  );
};

export default MixCurentComponent;

MixCurentComponent.propTypes = {
  dataRow: PropTypes.object,
  dataKey: PropTypes.string,
  currentKey: PropTypes.string,
  totals: PropTypes.object,
};
