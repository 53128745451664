export const getFilterOptionResults = (
  results: Record<string, string>[],
  dataKey: string,
  countries: Record<string, string>,
) =>
  results &&
  results.map((item) => {
    // treat product differently as it's now two separate queries
    if (
      dataKey === 'Product.product_id_sales' ||
      dataKey === 'Product.product_id_inventory'
    ) {
      return {
        dimension: 'Product.product_id',
        title: item['Product.product_id'],
        id: item['Product.product_id'],
      };
    }
    if (dataKey === 'Ticket.shippingCountry') {
      return {
        dimension: dataKey,
        title: countries[item[dataKey]],
        id: item[dataKey],
      };
    }
    return {
      dimension: dataKey,
      title: item[dataKey],
      id: item[dataKey],
    };
  });
