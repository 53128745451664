/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable arrow-body-style */
import { Label } from '@autone/ui';
import { Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

import { Compare, ComparePercent, Current } from '../features/table';
import store from '../redux/store';

const AutoneTableFooter = ({
  isLoading,
  theme,
  tableConfig,
  data,
  metric,
  name,
  firstColumnSticky,
}) => {
  // Note for future enhancements:
  // Compare data keys derived from first 3 letters of string (e.g. wtd, qtd, ytd, mtd)

  const { calculationConfig } = store.getState().config;
  const calcType = calculationConfig[name];

  const borderRow = `1px solid ${theme.palette.grey[100]}`;

  const renderCell = (data, dataKey) => {
    // RETAIL CONFIGS
    if (calcType === 'mix') {
      if (
        dataKey === 'wtd-compare' ||
        dataKey === 'mtd-compare' ||
        dataKey === 'today-compare' ||
        dataKey === 'ytd-compare'
      ) {
        return <Label>0 pp</Label>;
      }

      return '100%';
    }

    if (
      dataKey === 'wtd-compare' ||
      dataKey === 'mtd-compare' ||
      dataKey === 'ytd-compare' ||
      dataKey === 'today-compare'
    ) {
      return (
        <Compare
          dataRow={data}
          dataKey={
            dataKey && dataKey.includes('today')
              ? `today-previous`
              : `${dataKey.substring(0, 3)}-previous`
          }
          metric={metric}
          currentKey={
            dataKey && dataKey.includes('today')
              ? 'today'
              : dataKey.substring(0, 3)
          }
        />
      );
    }

    if (
      dataKey === 'wtd-compare-percent' ||
      dataKey === 'mtd-compare-percent' ||
      dataKey === 'ytd-compare-percent' ||
      dataKey === 'today-compare-percent'
    ) {
      return (
        <ComparePercent
          dataRow={data}
          currentKey={
            dataKey && dataKey.includes('today')
              ? 'today'
              : dataKey.substring(0, 3)
          }
          compareKey={
            dataKey && dataKey.includes('today')
              ? `today-previous`
              : `${dataKey.substring(0, 3)}-previous`
          }
          totalSum
        />
      );
    }

    if (
      dataKey === 'wtd' ||
      dataKey === 'mtd' ||
      dataKey === 'ytd' ||
      dataKey === 'today'
    ) {
      return <Current dataRow={data} dataKey={dataKey} metric={metric} />;
    }

    // EXPLORER CONFIGS
    if (dataKey === 'primary_sales_val' || dataKey === 'comparison_sales_val') {
      return <Current dataRow={data} dataKey={dataKey} metric="value" />;
    }

    if (dataKey === 'primary_sales_vol' || dataKey === 'comparison_sales_vol') {
      return <Current dataRow={data} dataKey={dataKey} metric="volume" />;
    }

    if (dataKey === 'difference_sales_val') {
      return (
        <Compare
          dataRow={data}
          dataKey="comparison_sales_val"
          metric="value"
          currentKey="primary_sales_val"
        />
      );
    }

    if (dataKey === 'difference_sales_vol') {
      return (
        <Compare
          dataRow={data}
          dataKey="comparison_sales_vol"
          metric="volume"
          currentKey="primary_sales_vol"
        />
      );
    }

    if (dataKey === 'percentage_difference_sales_val') {
      return (
        <ComparePercent
          dataRow={data}
          currentKey="primary_sales_val"
          compareKey="comparison_sales_val"
          calculationType="explorer"
        />
      );
    }

    if (dataKey === 'percentage_difference_sales_vol') {
      return (
        <ComparePercent
          dataRow={data}
          currentKey="primary_sales_vol"
          compareKey="comparison_sales_vol"
          calculationType="explorer"
        />
      );
    }

    return <></>;
  };

  return (
    <TableRow>
      <TableCell
        sx={{
          pt: theme.spacing(1),
          borderTop: borderRow,
          zIndex: '9999',
          position: 'sticky',
          left: 0,
          background: 'white',
          color: `${theme.palette.text.primary}`,
          ...(firstColumnSticky && {
            minWidth: firstColumnSticky?.minWidth,
          }),
        }}
        align="left"
        sticky={firstColumnSticky ? 'left' : ''}
      >
        {isLoading ? (
          <Skeleton height={30} width={80} />
        ) : (
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Total
          </Typography>
        )}
      </TableCell>
      {tableConfig &&
        tableConfig
          // TEMPORARY SOLUTION DURING DEVELOPMENT
          .slice(1)
          .filter((d) => d.active)
          .map(({ dataKey }) => {
            return (
              <TableCell
                key={dataKey}
                align="left"
                sx={{
                  pt: theme.spacing(1),
                  borderTop: borderRow,
                  zIndex: '99',
                  textAlign: 'right',
                  position: 'sticky',
                  left: 0,
                  background: 'white',
                  fontWeight: 'bold',
                  color: `${theme.palette.text.primary}`,
                }}
              >
                {isLoading ? (
                  <Skeleton height={30} width={80} />
                ) : (
                  data && renderCell(data, dataKey)
                )}
              </TableCell>
            );
          })}
    </TableRow>
  );
};

export default AutoneTableFooter;

AutoneTableFooter.propTypes = {
  isLoading: PropTypes.bool,
  theme: PropTypes.object,
  data: PropTypes.object,
  tableConfig: PropTypes.array,
  metric: PropTypes.string,
  name: PropTypes.string,
  firstColumnSticky: PropTypes.object,
};
