import { equals } from 'ramda';
import { useRef } from 'react';

export default function useDeepCompareMemorise<T>(value: T) {
  const ref = useRef<T | null>(null);

  if (!equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
