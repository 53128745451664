import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';

import { FilterOutput } from '../../types/filters';

import SearchResultItem from './SearchResultItem';
import { SearchbarResultsStyle } from './styles';

const searchBoxStyles = {
  py: 5,
  px: 4,
};

const RESULTS_SPACING = 1;

const SearchResults = ({
  filteredResults,
  setInputValue,
  inputValue,
  handleClose,
}: {
  filteredResults: FilterOutput[] | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  handleClose: () => void;
}) => {
  if (!filteredResults)
    return (
      <SearchbarResultsStyle>
        <Stack sx={{ ...searchBoxStyles }}>
          <Stack gap={1}>
            <Skeleton height={50} width={120} />
            <Skeleton height={60} width={300} />
            <Skeleton height={60} width={300} />
            <Skeleton height={60} width={300} />
          </Stack>
        </Stack>
      </SearchbarResultsStyle>
    );

  if (filteredResults.length === 0)
    return (
      <SearchbarResultsStyle>
        <Stack sx={{ ...searchBoxStyles }} alignItems={'center'}>
          <Typography>No results found</Typography>
        </Stack>
      </SearchbarResultsStyle>
    );

  return (
    <SearchbarResultsStyle>
      <Stack sx={{ ...searchBoxStyles }}>
        <Typography sx={{ fontWeight: 500, mb: 4, px: 1 }}>
          Search is "{inputValue}"
        </Typography>
        <Stack gap={RESULTS_SPACING}>
          {filteredResults.map((item, idx) => {
            const { title, options, dimension } = item;
            const numberOfOptions = options.length;
            return (
              <SearchResultItem
                key={idx}
                dimension={dimension}
                title={title}
                options={options}
                numberOfOptions={numberOfOptions}
                setInputValue={setInputValue}
                handleClose={handleClose}
              />
            );
          })}
        </Stack>
      </Stack>
    </SearchbarResultsStyle>
  );
};

export default SearchResults;
