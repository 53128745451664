import { isKeyInType, MIconButton } from '@autone/ui';
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  ClickAwayListener,
  Input,
  InputAdornment,
  Slide,
} from '@mui/material';
import { useMemo, useState } from 'react';

import store from '../../redux/store';

import SearchResults from './SearchResults';
import { SearchbarStyle } from './styles';

const Searchbar = () => {
  const [inputValue, setInputValue] = useState('');
  const { filters, activePage } = store.getState().filters;
  const [isOpen, setOpen] = useState(false);

  const showSearchResults = inputValue.length > 0;

  const filteredResults = useMemo(() => {
    if (
      !filters ||
      !activePage ||
      !isKeyInType(filters, activePage) ||
      !inputValue
    )
      return undefined;

    return filters[activePage]
      .map((filter) => ({
        ...filter,
        options: filter.options.filter(
          (option) =>
            option.title?.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.id.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      }))
      .filter((result) => result.options.length > 0);
  }, [filters, inputValue]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {showSearchResults && (
              <SearchResults
                filteredResults={filteredResults}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleClose={handleClose}
              />
            )}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};

export default Searchbar;
