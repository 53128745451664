import { capitalizeFirstLetter, generateImageUrl } from '@autone/ui';
import { Box, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { noDimension } from '../config/consts';

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'contain',
  borderRadius: theme.shape.borderRadiusSm,
  marginRight: theme.spacing(1),
}));

const ProductCell = ({ dataRow }) => {
  const {
    'Product.product_id': productId,
    'Product.styleDescription': productStyleDesc,
    'Product.colorDescription': productColorDesc,
    'Image.url': imageUrl,
  } = dataRow || {};

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ThumbImgStyle alt="product-image" src={generateImageUrl({ imageUrl })} />
      <Box
        sx={{
          py: 2,
          display: 'block',
        }}
      >
        {productId ? (
          <>
            <Typography component="div" variant="body2">
              {capitalizeFirstLetter(productStyleDesc)}
            </Typography>
            <Typography component="div" variant="body2">
              {capitalizeFirstLetter(productColorDesc)}
            </Typography>
            <Typography component="div" variant="body2">
              {productId}
            </Typography>
          </>
        ) : (
          <Typography component="div" variant="body2">
            {noDimension}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProductCell;

ProductCell.propTypes = {
  dataRow: PropTypes.object,
};
