import mixpanel, {
  Mixpanel as MixpanelInterface,
  People,
} from 'mixpanel-browser';

const { REACT_APP_MIXPANEL_TOKEN } = process.env;
const MODULE = 'insights';

type MixpanelActions = Pick<
  MixpanelInterface,
  'identify' | 'alias' | 'track'
> & {
  people: Partial<People>;
};

mixpanel.init(REACT_APP_MIXPANEL_TOKEN || '');

const actions: MixpanelActions = {
  identify: (id) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.track(name, { ...props, module: MODULE });
    }
  },
  people: {
    set: (props) => {
      if (REACT_APP_MIXPANEL_TOKEN && typeof props !== 'string') {
        mixpanel.people.set(props);
      }
    },
  },
};

export const Mixpanel = actions;
