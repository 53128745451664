import { INSIGHTS_PATH_APP, LightTooltip } from '@autone/ui';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  UPDATE_COMPARISON_RANGE,
  UPDATE_PRIMARY_RANGE,
} from '../../redux/explorer';
import { REMOVE_ALL_CURRENT_FILTERS } from '../../redux/filters';
import { SET_SELECTED_DATE } from '../../redux/retailCalendar';
import { LOAD_SELECTED_PRODUCT } from '../../redux/selected-product';
import { useAppSelector } from '../../redux/store';

export default function RemoveAllFilters() {
  const { activePage } = useAppSelector((state) => state.filters);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleRemoval = () => {
    dispatch(LOAD_SELECTED_PRODUCT(undefined));
    dispatch(SET_SELECTED_DATE(undefined));

    if (activePage) {
      dispatch(REMOVE_ALL_CURRENT_FILTERS({ activePage }));
    }
    if (pathname === INSIGHTS_PATH_APP.explorer) {
      dispatch(UPDATE_COMPARISON_RANGE(undefined));
      dispatch(UPDATE_PRIMARY_RANGE(undefined));
    }
  };

  return (
    <LightTooltip title="Remove all selections">
      <IconButton
        color="error"
        aria-label="remove selections"
        onClick={handleRemoval}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </LightTooltip>
  );
}
