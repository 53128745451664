export const SUCCESS_LOGIN = 'successful login';
export const FILTER = 'select filter';
export const SORT_PRODUCTS = 'sort products';
export const ORDER = 'order';
export const EXPORT_PRODUCTS = 'export products';
export const CHANGE_CALC_TYPE = 'change table calculatipon type';
export const FULL_SCREEN = 'full screen on table';
export const SELECT_REFERENCE_DATE = 'select retail reference date';
export const SELECT_DESELECT_EXPLORER_DIMENSION =
  'select or deselect a dimension on explorer report';
export const SELECT_DESELECT_ALL_EXPLORER_DIMENSION =
  'select or deselect all dimensions on explorer report';
export const SELECT_EXPLORER_DATE = 'select dat range on explorer report';
export const SELECTED_METRIC = 'change metrics';
