/* eslint-disable camelcase */
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';

import {
  compare,
  comparePercent,
  comparePercentExplorer,
  formatDateKeys,
  mixCompare,
  mixCurrent,
} from '../config/retail/calcs';

const useDataExport = ({
  dataToExport,
  headerList,
  percentageKeys,
  compareKeys,
  mixCurrentKeys,
  mixCompareKeys,
  calcType,
  totals,
}) => {
  const [csvData, setCsvData] = useState([]);

  const reduceArray = (array) =>
    array &&
    array.reduce((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});

  const calculatePercentageMetric = (keys, func, item) =>
    (keys &&
      keys.map(({ key: keyName, type }) => {
        const value = func(item, type);
        return { [keyName]: value };
      })) ||
    [];

  const calculateMetric = (keys, func, item) =>
    (keys &&
      keys.map(({ key: keyName, type }) => {
        const value = func(item, keyName, type);
        return { [keyName]: value };
      })) ||
    [];

  const calculateMix = (keys, func, item, totals) =>
    (keys &&
      keys.map(({ key: keyName }) => {
        const value = func(item, keyName, totals);
        return { [keyName]: value };
      })) ||
    [];

  const calculateMixComp = (keys, func, item, totals) =>
    (keys &&
      keys.map(({ key: keyName, type }) => {
        const value = func(item, keyName, type, totals);
        return { [keyName]: value };
      })) ||
    [];

  useEffect(() => {
    if (dataToExport) {
      // map csv data
      const csvData =
        dataToExport &&
        dataToExport.map((item) => {
          // CALCULATE PERCENTAGES
          const percentages = calculatePercentageMetric(
            percentageKeys,
            comparePercent,
            item,
          );
          const percentageObj = reduceArray(percentages);

          // CALCULATE COMPARE
          const compares = calculateMetric(compareKeys, compare, item);
          const compareObj = reduceArray(compares);

          // CALCULATE MIX
          const mix = calculateMix(mixCurrentKeys, mixCurrent, item, totals);
          const mixObj = reduceArray(mix);

          // CALCULATE MIX COMPARE
          const mixComp = calculateMixComp(
            mixCompareKeys,
            mixCompare,
            item,
            totals,
          );
          const mixCompObj = reduceArray(mixComp);

          // EXPLORER CALCS
          const difference_sales_val = compare(
            item,
            'comparison_sales_val',
            'primary_sales_val',
          );

          const difference_sales_vol = compare(
            item,
            'comparison_sales_vol',
            'primary_sales_vol',
          );

          const percentage_difference_sales_val = comparePercentExplorer(
            item,
            'primary_sales_val',
            'comparison_sales_val',
          );

          const percentage_difference_sales_vol = comparePercentExplorer(
            item,
            'primary_sales_vol',
            'comparison_sales_vol',
          );

          const formattedItemWithDates = formatDateKeys(item);

          return {
            ...formattedItemWithDates,
            ...percentageObj,
            ...compareObj,
            ...mixObj,
            ...mixCompObj,
            difference_sales_val,
            difference_sales_vol,
            percentage_difference_sales_val,
            percentage_difference_sales_vol,
          };
        });

      setCsvData(csvData);
    }
  }, [dataToExport, calcType]);

  const createFormattedCsVData = (csvData, csvHeader) => {
    if (!csvData || !csvHeader) {
      return [];
    }

    const formattedData = csvData.map((item) => {
      const row = csvHeader.map(({ key }) => item?.[key] ?? '');

      return row;
    });

    return [['SEP=,']].concat(
      [csvHeader.map(({ label }) => label)],
      formattedData,
    );
  };

  return {
    csvData,
    csvHeader: [...headerList],
    createFormattedCsVData,
  };
};

export default useDataExport;
