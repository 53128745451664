import { capitalizeFirstLetter } from '@autone/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { noDimension } from '../../config/consts';

// eslint-disable-next-line arrow-body-style
const Dimension = ({ dataRow, dataKey }) => {
  return (
    <span>
      {dataRow &&
        dataKey &&
        (dataRow[dataKey]
          ? capitalizeFirstLetter(dataRow[dataKey])
          : noDimension)}
    </span>
  );
};

export default Dimension;

Dimension.propTypes = {
  dataRow: PropTypes.object,
  dataKey: PropTypes.string,
};
