import { Inventory, Product } from '../../types';

export const calculateBestsellerMetrics = (
  combinedSalesInventoryData: (Product & Inventory)[],
) =>
  combinedSalesInventoryData?.map((item) => {
    const totalSalesValue = item['Sales.totalSalesValue'] || '0';
    const totalSalesQuantity = item['Sales.totalSalesQuantity'] || '0';
    const purchasedSalesQuantity = item['Sales.purchasedSalesQuantity'] || '0';
    const returnedSalesQuantity = item['Sales.returnedSalesQuantity'] || '0';

    const totalInventoryQuantity =
      Number(item['Inventory.currentOnHandQuantity'] || 0) +
      Number(item['Inventory.currentInWarehouseQuantity'] || 0) +
      Number(item['Inventory.currentInTransitQuantity'] || 0);

    const totalInventoryValue =
      Number(item['Inventory.currentOnHandValue'] || 0) +
      Number(item['Inventory.currentInWarehouseValue'] || 0) +
      Number(item['Inventory.currentInTransitValue'] || 0);

    const sellThrough =
      parseInt(totalSalesQuantity, 10) /
      (parseInt(totalSalesQuantity, 10) + totalInventoryQuantity);

    const returnRate =
      parseInt(returnedSalesQuantity, 10) /
      parseInt(purchasedSalesQuantity, 10);

    return {
      ...item,
      'Sales.totalSalesValue': totalSalesValue,
      'Sales.totalSalesQuantity': totalSalesQuantity,
      totalInventoryQuantity,
      totalInventoryValue: totalInventoryValue || 'nm',
      sellThrough: Number.isFinite(sellThrough) ? sellThrough : 'nm',
      returnRate: Number.isFinite(returnRate) ? returnRate : 'nm',
    };
  });
