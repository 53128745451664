/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import {
  fNumber,
  fNumberWithCommas,
  NO_DATA_SUBTITLE,
  NO_DATA_TITLE,
  NoDataMessage,
} from '@autone/ui';
import { Box, Card, CardHeader, Skeleton } from '@mui/material';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import store from '../redux/store';

import ChartTheme from './ChartTheme';

const TimeSeriesChart = ({ data, isLoading, type = 'area', title }) => {
  const { selectedVariance } = store.getState().filters;

  const series1Array = data && data.map((item) => item['chart-ty'] || 0);
  const series2Array = data && data.map((item) => item['chart-ly'] || 0);
  const categories = data && data.map((item) => item.date);

  let series;

  if (selectedVariance === 'this-year') {
    series = [
      {
        name: 'TY',
        data: series1Array,
      },
    ];
  } else {
    series = [
      {
        name: 'TY',
        data: series1Array,
      },
      {
        name: 'LY',
        data: series2Array,
      },
    ];
  }

  const options = merge(ChartTheme(), {
    chart: {
      animations: {
        enabled: false,
      },
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    // markers: { size: 0 },
    xaxis: {
      type: 'datetime',
      categories,
      labels: {
        format: 'dd/MM',
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => value && fNumberWithCommas(value),
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM',
      },
      y: {
        formatter: (seriesName) => seriesName && fNumber(seriesName),
      },
    },
  });

  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: 'h4' }} title={title} />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        {!isLoading && data ? (
          data.length === 0 ? (
            <NoDataMessage title={NO_DATA_TITLE} subtitle={NO_DATA_SUBTITLE} />
          ) : (
            <ReactApexChart
              type={type}
              series={series}
              options={options}
              height={200}
            />
          )
        ) : (
          <Box sx={{ minHeight: 215 }}>
            <Skeleton sx={{ minHeight: 50 }} />
            <Skeleton sx={{ minHeight: 50 }} />
            <Skeleton sx={{ minHeight: 50 }} />
            <Skeleton sx={{ minHeight: 50 }} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default TimeSeriesChart;

TimeSeriesChart.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
};
