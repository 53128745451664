import { REMOVE_FILTER_CHIP } from '../../redux/filters';
import store from '../../redux/store';

export const handleClearDate = ({ dispatch, dimension, activePage }) => {
  // get current filters
  const { filters } = store.getState().filters;

  // check if selections exist in rendered sidebar filters
  const filterGroupExists =
    filters &&
    filters[activePage] &&
    filters[activePage]?.some(
      (filterGroup) => filterGroup.dimension === dimension,
    );

  // clear dates if an active filter exists
  if (filterGroupExists) {
    dispatch(REMOVE_FILTER_CHIP({ activePage, dimension }));
  }
};
