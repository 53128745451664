/* eslint-disable max-lines-per-function */
import { compare, comparePercent, current } from '../config';

export const getTableTotals = (tableData, metric) => {
  const sumTotals = (period) =>
    tableData
      // remove undefined before reduce, ensures we can capture 'nm' in totals
      // .filter((v) => !Number.isNaN(1 * v))
      // reduce by summing values which are not undefined
      .reduce((acc, obj) => {
        // extra or catch for undefined/null
        const total = acc + (1 * obj[period] || 0);
        return total;
      }, 0);

  // Sum table data
  const today = sumTotals('today');
  const todayCompare = sumTotals('today-compare');
  const wtd = sumTotals('wtd');
  const wtdCompare = sumTotals('wtd-compare');
  const mtd = sumTotals('mtd');
  const mtdCompare = sumTotals('mtd-compare');
  const ytd = sumTotals('ytd');
  const ytdCompare = sumTotals('ytd-compare');
  const primarySalesVal = sumTotals('primary_sales_val');
  const primarySalesVol = sumTotals('primary_sales_vol');
  const comparisonSalesVal = sumTotals('comparison_sales_val');
  const comparisonSalesVol = sumTotals('comparison_sales_vol');

  // store in object & calculate current and compare
  const tableTotals = {
    today: current({ today }, 'today', metric),
    'today-previous': todayCompare,
    'today-compare': compare({ today, todayCompare }, 'todayCompare', 'today'),
    'today-compare-percent': comparePercent(
      { today, 'today-compare': wtdCompare },
      'today',
    ),
    wtd: current({ wtd }, 'wtd', metric),
    'wtd-previous': wtdCompare,
    'wtd-compare': compare({ wtd, wtdCompare }, 'wtdCompare', 'wtd'),
    'wtd-compare-percent': comparePercent(
      { wtd, 'wtd-compare': wtdCompare },
      'wtd',
    ),
    mtd: current({ mtd }, 'mtd', metric),
    'mtd-previous': mtdCompare,
    'mtd-compare': compare({ mtd, mtdCompare }, 'mtdCompare', 'mtd'),
    'mtd-compare-percent': comparePercent(
      { mtd, 'mtd-compare': mtdCompare },
      'mtd',
    ),
    ytd: current({ ytd }, 'ytd', metric),
    'ytd-previous': ytdCompare,
    'ytd-compare': compare({ ytd, ytdCompare }, 'ytdCompare', 'ytd'),
    'ytd-compare-percent': comparePercent(
      { ytd, 'ytd-compare': ytdCompare },
      'ytd',
    ),
    primary_sales_val: current(
      { primary_sales_val: primarySalesVal },
      'primary_sales_val',
      'value',
    ),
    primary_sales_vol: current(
      { primary_sales_vol: primarySalesVol },
      'primary_sales_vol',
      'volume',
    ),
    comparison_sales_val: current(
      { comparison_sales_val: comparisonSalesVal },
      'comparison_sales_val',
      'value',
    ),
    comparison_sales_vol: current(
      { comparison_sales_vol: comparisonSalesVol },
      'comparison_sales_vol',
      'volume',
    ),
    difference_sales_val: compare(
      {
        primary_sales_val: primarySalesVal,
        comparison_sales_val: comparisonSalesVal,
      },
      'comparisonSalesVal',
      'primarySalesVal',
    ),
    difference_sales_vol: compare(
      {
        primary_sales_vol: primarySalesVol,
        comparison_sales_vol: comparisonSalesVol,
      },
      'comparisonSalesVol',
      'primarySalesVol',
    ),
    percentage_difference_sales_val: comparePercent(
      {
        primary_sales_val: primarySalesVal,
        comparison_sales_val: comparisonSalesVal,
      },
      'primary_sales_val',
    ),
    percentage_difference_sales_vol: comparePercent(
      {
        primary_sales_vol: primarySalesVol,
        comparison_sales_vol: comparisonSalesVol,
      },
      'primary_sales_vol',
    ),
  };

  return tableTotals;
};
