/* eslint-disable complexity */
import { fCurrency } from '@autone/ui';
import PropTypes from 'prop-types';

const currencyTo1DP = ({ value, selectedCurrency }) => (
  <>{fCurrency(value || 0, selectedCurrency, 1)}</>
);

export default currencyTo1DP;

currencyTo1DP.propTypes = {
  value: PropTypes.number,
  selectedCurrency: PropTypes.string,
};
