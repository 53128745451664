import { AuthGuard, INSIGHTS_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import DashboardLayout from '../layout';

const AppRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: INSIGHTS_PATH_APP.root,
      component: lazy(() => import('../views/Retail')),
    },
    {
      exact: true,
      path: '/retail',
      component: () => <Redirect to={INSIGHTS_PATH_APP.root} />,
    },
    {
      exact: true,
      path: INSIGHTS_PATH_APP.bestsellers,
      component: lazy(() => import('../views/Bestsellers')),
    },
    {
      exact: true,
      path: INSIGHTS_PATH_APP.explorer,
      component: lazy(() => import('../views/Explorer')),
    },
    // if path is not recognised then redirect back to the homepage
    {
      component: () => <Redirect to={INSIGHTS_PATH_APP.root} />,
    },
  ],
};

export default AppRoutes;
