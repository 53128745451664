import { isKeyInType } from '@autone/ui';
import { filter as _filter } from 'lodash';
import { useEffect, useState } from 'react';

import { ActivePage, CubeQuery } from '../types';
import { Filters, Option } from '../types/filters';
//@ts-ignore
import { queryBuilder } from '../utils';

import useDeepCompareMemorise from './hooks/deep-compare-memorise';

type ProductFilter = {
  dimension: string;
  title: string;
  id: string;
  active: boolean;
};

type BuildQueryTypes = {
  query: CubeQuery[];
  filters: Filters | null;
  activePage: ActivePage;
  filterQuery?: boolean;
  productFilters?: ProductFilter[];
};

const applyFilters = (
  filters: Filters | null,
  activePage: ActivePage,
  productFilters?: ProductFilter[],
) =>
  filters &&
  isKeyInType(filters, activePage) &&
  filters[activePage] &&
  filters[activePage].map((filter) => {
    const { options, dimension, operator } = filter;
    const activeFilterOptions = _filter(options, ['active', true]);

    // if product filters are passed we override the active filter options
    // this to ensure we can use products with no sales in the bestsellers inventory query
    if (dimension === 'Product.product_id' && productFilters) {
      return {
        dimension,
        operator,
        options: productFilters,
      };
    }

    return {
      dimension,
      operator,
      options: activeFilterOptions,
    };
  });

const getQuery = (
  query: CubeQuery[],
  activeFilters:
    | false
    | { dimension?: string; operator: string | undefined; options: Option[] }[]
    | null,
  filterQuery?: boolean,
) =>
  query &&
  query.map((item) => {
    const {
      type,
      dataKey,
      query: { measure, dimension, timeDimension },
    } = item;

    const { query, drillDimension, drilldownHeader } = queryBuilder(
      item,
      activeFilters,
      type,
      filterQuery,
    );

    return {
      dataKey,
      measureName: measure,
      dimensionName: drillDimension || dimension,
      drilldownHeader,
      timeDimension,
      query,
    };
  });

export const useBuildCubeQuery = ({
  query,
  filters,
  activePage,
  filterQuery = false,
  productFilters,
}: BuildQueryTypes) => {
  const [cubeQuery, setCubeQuery] = useState<CubeQuery[] | undefined>();

  const activeFilters =
    applyFilters(filters, activePage, productFilters) ?? null;
  const memoisedQuery = useDeepCompareMemorise(query);

  useEffect(() => {
    if (filters && isKeyInType(filters, activePage) && query) {
      const finalQuery = getQuery(query, activeFilters, filterQuery);
      setCubeQuery(finalQuery);
    }
  }, [useDeepCompareMemorise(filters), memoisedQuery]);

  return {
    cubeQuery,
  };
};

export const getBuildQuery = ({
  query,
  filters,
  activePage,
  filterQuery = false,
  productFilters,
}: BuildQueryTypes) => {
  const activeFilters = applyFilters(filters, activePage, productFilters);
  return getQuery(query, activeFilters, filterQuery);
};
